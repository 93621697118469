import type { Product } from '~/utils/crystallize/product'

export function useProductCard<T extends Product | undefined>(product: MaybeRef<T>) {
  const cart = useCart()

  return computed(() => {
    const p = unref(product)
    // TODO: Not sure if it's possible, but would be nice to not have to cast here
    return p && getProductCard(p, unref(cart.data.value?.cartItems)) as (T extends Product ? ProductCard : undefined)
  })
}
