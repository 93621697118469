import type { Product, ProductVariant } from '~/utils/crystallize/product'

export function useProductVariant(product?: MaybeRef<ProductVariant | ProductCard | Product | undefined>) {
  return computed(() => {
    const value = unref(product)
    if (!value) {
      return undefined
    }

    if ('variant' in value) {
      return value.variant
    }

    if (isVariant(value)) {
      return value
    }

    return getDefaultVariant(value)
  })
}
